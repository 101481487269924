import { Unity, useUnityContext } from "react-unity-webgl";
import "./App.css";
import { BrowserView, MobileView } from "react-device-detect";
import {useState , useEffect , useRef} from "react";
import React from "react";
function App() {
  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "HeteroMetaUnity/Build/webgl-build.loader.js",
    dataUrl: "HeteroMetaUnity/Build/webgl-build.data",
    frameworkUrl: "HeteroMetaUnity/Build/webgl-build.framework.js",
    codeUrl: "HeteroMetaUnity/Build/webgl-build.wasm",
  });
  const loadingPercentage = Math.round(loadingProgression * 100);
  const videoRef = useRef(null);
 const [height, setHeight] = useState(400);

  useEffect(() => {
    let timeout;
 
    // handles setting the iframe's height on window resize to maintain aspect ratio
    const updateSize = () => {
      if (!videoRef?.current) return;
 
      clearTimeout(timeout);
 
      timeout = setTimeout(() => {
        setHeight((videoRef?.current?.clientWidth) / (16 / 9));
      }, 100);
    };
 
    updateSize();
 
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [videoRef]);

  return (
    <>
      <MobileView>
        <img className="loading-unity-gif-mobile" src="loading.gif" />
        <div className="unity-loading-text-mobile-container">
          <p className="unity-loading-text-mobile">
            Please Visit Our Desktop Page For Better Experience
          </p>
        </div>
      </MobileView>
      <BrowserView>
        {isLoaded == false ? (
          <>
            {/* <Canvas className="stars-loading">
                <Stars></Stars>
              </Canvas> */}
            {/* <div id="loading">
                  <img id="loadingGif" src="loading.gif" />
                </div> */}
            {/* <div className="loadingEarth">
                  <img
                    className="loadingGifEarth"
                    src="ezgif.com-gif-maker.gif"
                  />
                </div>
                <div className="containerUnity">
                  <p className="unity-loading">
                    Earth 2.0 Loading... ({loadingPercentage}%)
                  </p>
                </div> */}
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                onClick={() =>
                  window.open(
                    "https://twitter.com/HeteroMeta",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                type="button"
                class="btn-twitter"
              ></button>
              <button onClick={() =>
                  window.open(
                    "https://discord.gg/WuvmMPh5rY",
                    "_blank",
                    "noopener,noreferrer"
                  )
                } type="button" class="btn-discord"></button>
              <button onClick={() =>
                  window.open(
                    "https://www.instagram.com/heterometa/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                } type="button" class="btn-instagram"></button>
              <button onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCUhu_lKvaf5PCOmrD0Oq2tw",
                    "_blank",
                    "noopener,noreferrer"
                  )
                } type="button" class="btn-youtube"></button>
            </div>
            <div class="parent">
              <div class="child">
                <p className="unity-loading noselect">
                  {/* Earth 2.0(Alpha Version2) Loading... ({loadingPercentage}
                  %) */}
                  Earth 2.0 Alpha Version is Loading...
                </p>
                <img
                  src="ezgif.com-gif-maker.gif"
                  className="noselect loading-image"
                />
              </div>
            </div>
          </>
        ) : (
          <div></div>
        )}
        <Unity
          className="unity"
          //ref={videoRef}
          unityProvider={unityProvider}
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "black",
          }}
        />
      </BrowserView>
    </>
  );
}

export default App;
